import X from "assets/X.svg"
import ArticleTile from "components/ArticleTile"
import Button from "components/Button"
import InvisibleButton from "components/InvisibleButton"
import Fuse from "fuse.js"
import { graphql } from "gatsby"
import { debounce } from "lodash-es"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useReducer, useRef } from "react"
import theme from "styles/theme"

const PER_PAGE = 20

const initialState = {
  filters: new Set([]),
  searchQuery: "",
  limit: PER_PAGE,
  pageSize: PER_PAGE,
}

const reducer = (state, { action, value }) => {
  switch (action) {
    case "setSearchQuery":
      return {
        ...state,
        limit: state.pageSize,
        searchQuery: value,
      }

    case "loadMore":
      return {
        ...state,
        limit: state.limit + state.pageSize,
      }

    case "toggleFilter": {
      const filters = new Set(state.filters)

      if (filters.has(value)) {
        filters.delete(value)
      } else {
        filters.add(value)
      }

      return {
        ...state,
        limit: state.pageSize,
        filters,
      }
    }

    case "clearFilters":
      return {
        ...state,
        limit: state.pageSize,
        filters: new Set([]),
      }

    default:
      return state
  }
}

const ArticleCollection = ({ title, articles, sections, limit, slug }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    limit,
    pageSize: limit,
  })
  const fuse = useMemo(() => new Fuse(articles, fuseConfig), [articles])

  const filteredArticles = useMemo(() => {
    const results =
      state.searchQuery.trim().length > 0
        ? fuse.search(state.searchQuery).map((res) => res.item)
        : articles

    return state.filters.size > 0
      ? results.filter(({ category }) => state.filters.has(category._id))
      : results
  }, [fuse, articles, state.filters, state.searchQuery])

  return (
    <div
      id={slug}
      css={{
        maxWidth: 1228 + 50,
        padding: "80px 25px",
        margin: "0 auto",
      }}
    >
      {title && (
        <h2 css={{ ...theme.h1, textAlign: "center", marginBottom: 36 }}>
          {title}
        </h2>
      )}

      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: 50,
          [theme.mq.mobile]: {
            display: "block",
          },
        }}
      >
        <div>
          <h4 css={{ ...theme.p3, fontWeight: 500, marginBottom: 8 }}>
            Filter
          </h4>

          <div
            css={{
              display: "flex",
              alignItems: "center",
              [theme.mq.mobile]: {
                flexWrap: "wrap",
              },
            }}
          >
            {sections.flatMap(({ categories }) =>
              categories
                // Hide categories that don't have any corresponding articles
                .filter(({ _id }) =>
                  articles.some(({ category }) => category._id === _id)
                )
                // Render buttons for each category
                .map(({ _id, name }) => (
                  <button
                    key={_id}
                    onClick={() => {
                      dispatch({ action: "toggleFilter", value: _id })
                    }}
                    css={{
                      ...theme.p3,
                      lineHeight: 1.2,
                      appearance: "none",
                      cursor: "pointer",
                      border: 0,
                      borderRadius: 4,
                      boxShadow: state.filters.has(_id)
                        ? theme.shadows.inset
                        : theme.shadows.tight,
                      background: theme.colors.floatingContainerBackground,
                      color: theme.colors.secondaryText,
                      padding: 12,
                      marginRight: 10,
                      marginBottom: 10,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {name}
                  </button>
                ))
            )}

            {state.filters.size > 0 && (
              <InvisibleButton
                css={{
                  ...theme.p3,
                  color: theme.colors.secondaryText,
                  textDecoration: "underline",
                  marginLeft: 10,
                  marginBottom: 10,
                }}
                onClick={() => {
                  dispatch({ action: "clearFilters" })
                }}
              >
                Clear filters
              </InvisibleButton>
            )}
          </div>
        </div>

        <div css={{ marginBottom: 10 }}>
          <SearchBox
            dispatch={dispatch}
            css={{
              [theme.mq.mobile]: {
                marginTop: 10,
              },
              [theme.mq.smallMobile]: {
                width: "100%",
              },
            }}
          />
        </div>
      </div>

      {filteredArticles.length > 0 ? (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridTemplateRows: "min-content",
            gap: 20,
            [theme.mq.tablet]: {
              gridTemplateColumns: "repeat(3, 1fr)",
            },
            [theme.mq.mobile]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
            [theme.mq.mobilePortrait]: {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          {filteredArticles.slice(0, state.limit).map((article) => (
            <ArticleTile
              key={article.slug.current}
              {...article}
              css={{
                gridColumn: article.featured && "span 2",
                gridRow: article.coverImage
                  ? article.featured
                    ? "span 6"
                    : "span 6"
                  : "span 3",
                gridAutoFlow: "dense",
                [theme.mq.mobile]: {
                  gridColumn: "span 1",
                  gridRow: article.coverImage ? "span 6" : "span 2",
                },
                [theme.mq.mobilePortrait]: {
                  gridRow: "span 1",
                },
              }}
              // css={{
              //   gridColumn: article.featured && "span 2",
              //   // gridRow: article.coverImage
              //   //   ? article.featured
              //   //     ? "span 6"
              //   //     : "span 6"
              //   //   : "span 6",
              //   gridRow: "span 6",
              //   gridAutoFlow: "dense",
              //   [theme.mq.mobile]: {
              //     gridColumn: "span 1",
              //     gridRow: article.coverImage ? "span 6" : "span 2",
              //   },
              //   [theme.mq.mobilePortrait]: {
              //     gridRow: "span 1",
              //   },
              // }}
              large={article.featured}
              showCategory
            />
          ))}
        </div>
      ) : (
        <div
          css={{
            ...theme.p3,
            padding: 30,
            margin: "80px auto 0",
            maxWidth: 300,
            background: theme.colors.floatingContainerBackground,
            color: theme.colors.secondaryText,
            boxShadow: theme.shadows.blur,
            borderRadius: 4,
            textAlign: "center",
          }}
        >
          No articles found
        </div>
      )}

      {filteredArticles.length > state.limit && (
        <div css={{ textAlign: "center", marginTop: 50 }}>
          <Button
            onClick={() => {
              dispatch({ action: "loadMore" })
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  )
}

export default ArticleCollection

ArticleCollection.propTypes = {
  title: PropTypes.string,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.shape({ current: PropTypes.string.isRequired })
        .isRequired,
      coverImage: PropTypes.shape({
        asset: PropTypes.shape({
          _id: PropTypes.string.isRequired,
        }),
      }),
      category: PropTypes.shape({
        name: PropTypes.string.isRequired,
        section: PropTypes.shape({
          name: PropTypes.string.isRequired,
          color: PropTypes.shape({
            hex: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  limit: PropTypes.number.isRequired,
  slug: PropTypes.string,
}

const SearchBox = ({ dispatch, className }) => {
  const searchRef = useRef()

  const updateSearchQuery = useCallback(
    debounce(
      (value) =>
        dispatch({
          action: "setSearchQuery",
          value: searchRef.current.value,
        }),
      500
    ),
    []
  )

  return (
    <input
      ref={searchRef}
      type="search"
      placeholder="Search"
      onChange={updateSearchQuery}
      css={{
        ...theme.p3,
        appearance: "none",
        border: 0,
        borderRadius: 4,
        background: theme.colors.insetBackground,
        color: theme.colors.secondaryText,
        boxShadow: theme.shadows.inset,
        padding: "8px 12px",
        lineHeight: 1,
        "::-webkit-search-cancel-button": {
          appearance: "none",
          width: 16,
          height: 16,
          background: `url(${X}) no-repeat`,
          cursor: "pointer",
        },
      }}
      className={className}
    />
  )
}

SearchBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const fuseConfig = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  keys: ["title", "tags"],
}

export const query = graphql`
  fragment ArticleCollectionBlock on SanityArticleCollection {
    title
    articles {
      ...ArticleTile
      featured
      tags
      category {
        _id
      }
    }
    sections {
      name
      color {
        hex
      }
      categories {
        _id
        name
      }
    }
    limit
  }
`
